import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 通用样式
import '@/assets/control.css'
// 引入动画
import 'animate.css/animate.min.css'

import '@/utils/mixin.js'
import 'wowjs/css/libs/animate.css'
import { WOW } from 'wowjs'

Vue.config.productionTip = false
Vue.prototype.$wow = new WOW({
  boxClass: 'wow',
  animateClass: 'animated',
  offset: 0,
  live: true,
  mobile: true,
  callback(box) {
    console.log(box);
  }
})


new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
